import { CheckCircledIcon } from '@radix-ui/react-icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';

import { ROUTES, useSocketEvent } from 'shared/constants';
import { getMyProfileService, updateAccountService } from 'shared/services';
import useAuthStore from 'shared/store/auth.slice';
import {
  ErrorResponse,
  ISocketResponse,
  IUpdateAccountRequestParams,
  IVideoUploadStatus,
} from 'shared/types';
import { generateErrorToast, getDeviceName } from 'shared/utils';

export const useFetchProfile = () => {
  const setUserProfile = useAuthStore((state) => state.setUserProfile);
  const credentials = useAuthStore((state) => state.credentials);
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery({
    queryKey: ['profile'],
    queryFn: getMyProfileService,
    enabled: !!credentials?.token,
    retry: false,
  });

  const { mutate: update } = useMutation({
    mutationFn: ({ updatedInfo, id }: IUpdateAccountRequestParams) =>
      updateAccountService({ id, updatedInfo }),
  });

  function onVideoFinishUploading({ video }: IVideoUploadStatus) {
    queryClient.invalidateQueries({ queryKey: ['user_video_list'] });
    queryClient.invalidateQueries({ queryKey: ['user_video_detail'] });
    toast(
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1 [&_svg]:mt-0">
          <CheckCircledIcon color="white" className="size-4 mt-0" />
          <p className="leading-none text-robot mt-1">Success!</p>
        </div>
        <Link to={`/${ROUTES.MY_DATA}/${video.id}`}>
          Your video {video.title ?? ''} is now {video.status}.
        </Link>
      </div>,
      {
        duration: 10000,
        position: 'bottom-center',
        style: {
          background: '#5eb26d',
          //borderColor: 'white',
          marginTop: '50px',
        },
      }
    );
  }

  function onVideoErrorUploading({ video }: IVideoUploadStatus) {
    generateErrorToast(
      `Oops! Unable to load the video '${video?.title ?? ''}' at the moment. Please try again.` as unknown as ErrorResponse
    );
  }

  useSocketEvent('.videoFinishProcessing', (data: ISocketResponse<IVideoUploadStatus>) => {
    if (data?.data?.status === 'ok') {
      onVideoFinishUploading(data.data);
    }
  });

  useSocketEvent('.videoErrorProcessing', (data: ISocketResponse<IVideoUploadStatus>) => {
    if (data?.data?.status === 'ok') {
      onVideoErrorUploading(data.data);
    }
  });

  useEffect(() => {
    if (data) {
      setUserProfile(data);
      update({
        id: data.id!,
        updatedInfo: {
          device_info: {
            app_version:
              (import.meta.env.VERCEL_DEPLOYMENT_ID ?? '') +
              ';' +
              (import.meta.env.VITE_VERCEL_GIT_COMMIT_MESSAGE ?? '').substring(0, 100),
            device_name: getDeviceName(),
          },
        },
      });
    }
  }, [data]);

  return { isFetching };
};
