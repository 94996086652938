import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';

import { Toaster } from 'shared/ui';

const EmbeddingLayout: FC = () => {
  document.getElementById('pageloader-overlay')?.remove();
  const body = document.getElementsByTagName('BODY')[0] as HTMLElement;
  if (body) body.style.backgroundColor = 'transparent';
  return (
    <div className="flex absolute w-full top-0 left-0 max-w-[100vw] overflow-hidden">
      {<Outlet />}
      <Toaster richColors position="top-left" />
    </div>
  );
};

export default memo(EmbeddingLayout);
