import * as React from 'react';

import { ITextareaProps } from 'shared/types';
import { BasicTextEditor } from 'shared/ui/TextEditor';
import { cn } from 'shared/utils';

import { Label } from '../Label';

const Textarea = React.forwardRef<HTMLDivElement, ITextareaProps>(
  ({
    className,
    error,
    characterLimit,
    labelSize,
    wrapClassName,
    title,
    loading = false,
    ...props
  }) => {
    const labelStyles = labelSize === 'sm' ? 'text-[12px]' : 'text-[15px]'; //`text-[${labelSize === 'sm' ? '12' : '15'}px]`;

    return (
      <div className={cn(`grid w-full items-center gap-1 relative`, wrapClassName)}>
        {title && (
          <Label
            htmlFor={props?.name ?? 'input'}
            className={cn(
              'block mb-2 text-sm font-normal text-gray-900 dark:text-white',
              labelStyles
            )}
          >
            {title}
          </Label>
        )}
        <div
          className={cn(
            'bg-gray-50 rounded-lg border ' +
              `${error?.message ? 'border-rose-500' : 'border-gray-300 dark:border-gray-300'} ` +
              'min-h-[80px] text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 file:border-0' +
              "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none  focus-visible:ring-${error?.message ? 'rose-500' : 'midnight'}  disabled:cursor-not-allowed disabled:bg-bgGrey",
            className
          )}
        >
          <BasicTextEditor
            value={props.value?.toString()}
            onValueChange={(str) => props.onValueChange?.(str)}
            outputValue="html"
            disabled={false}
            characterLimit={characterLimit}
            contentClass="mx-auto text-left font-sans"
            className={cn('', className)}
          />
        </div>
        {error?.message && (
          <span className="absolute text-[10px] inset-y-full left-2 text-rose-500">
            {error?.message}
          </span>
        )}
      </div>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
