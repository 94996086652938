import { memo, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'shared/constants';
import useAuthStore from 'shared/store/auth.slice';
import { PageLoader } from 'entities/components';

const PrivateRoute = () => {
  const credentials = useAuthStore((state) => state.credentials);
  if (!credentials?.token) return <Navigate to={ROUTES.HOME} />;

  return (
    <Suspense fallback={<PageLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default memo(PrivateRoute);
