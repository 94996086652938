import { memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Spinner } from 'shared/assets/icon/loading-loader.svg';
import logoIcon from 'shared/assets/logo/authLogo.svg';
import { ROUTES } from 'shared/constants';
import { Skeleton } from 'shared/ui';

export const PageLoader = () => {
  const { pathname } = useLocation();
  const isAuthRoute = Object.values(ROUTES.AUTH).some((route) =>
    pathname.includes(route.replace(/^\//, ''))
  );

  return (
    <div className="absolute flex items-center justify-center bg-white top-0 left-0 size-full z-10">
      <Skeleton
        className={`rounded-none animate-skeleton_none px-4 flex items-center justify-${isAuthRoute ? 'center' : 'start'}  bg-midnight fixed top-0 left-0 w-full h-[${isAuthRoute ? '110' : '40'}px] md:h-[${isAuthRoute ? '110' : '76'}px]`}
      >
        {!isAuthRoute ? (
          <LazyLoadImage
            loading="lazy"
            src={logoIcon}
            className="w-[84px] md:w-[136px] sm:ml-[60px]"
          />
        ) : (
          <LazyLoadImage
            loading="lazy"
            src={logoIcon}
            className="sm:w-[342px] sm:h-[110px] w-[192px] h-[62px]"
          />
        )}
      </Skeleton>
      <Skeleton className="rounded-none animate-skeleton_none bg-midnight fixed bottom-0 left-0 w-full h-[36px]" />
      <Spinner className="w-20 h-20 animate-spin" />
    </div>
  );
};

export default memo(PageLoader);
