/*
export const socketConfig: Partial<ManagerOptions & SocketOptions> = {
  query: {
    token: `Bearer ${token}`,
    app_key: import.meta.env.VITE_APP_KEY,
    channel: `channel_${userID}`,
  },
};
*/

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useEffect } from 'react';

import { API_VI, instance } from 'shared/services';
import useAuthStore from 'shared/store/auth.slice.ts';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/named */

//import { ManagerOptions, SocketOptions } from 'socket.io-client';

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: Echo | undefined;
    currentWSUser: number | undefined;
  }
}

window.Pusher = Pusher;

function createSocketConnection(user_id: number | undefined, authToken?: string) {
  if (!window.Echo) {
    window.currentWSUser = user_id;
    window.Echo = new Echo({
      broadcaster: 'pusher',
      cluster: 'default',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      wsHost: import.meta.env.VITE_PUSHER_HOST,
      wssHost: import.meta.env.VITE_PUSHER_HOST,
      wsPort: import.meta.env.VITE_PUSHER_PORT,
      wssPort: import.meta.env.VITE_PUSHER_PORT,
      forceTLS: import.meta.env.VITE_PUSHER_FORCE_TLS !== 'false',
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      enableLogging: true,
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      authorizer: (channel: any, _options: any) => {
        return {
          authorize: (_socketId: any, callback: any) => {
            instance
              .get(API_VI + '/check/auth-ws-user', {
                headers: {
                  authorization: authToken,
                  'App-Key': import.meta.env.VITE_APP_KEY || '',
                  'Content-Type': 'application/json',
                },
                params: { channel: channel.name, socket_id: _socketId },
              })
              .then((response) => {
                //console.log("authorized", response.data.data);
                callback(null, response.data.data);
              })
              .catch((error) => {
                //console.error("not authorized", error.response.data)
                callback(error);
              });
          },
        };
      },
    });
  }
  return window.Echo;
}

function createSocketWithPrivateDefaultChannel(user_id: number | undefined, authToken?: string) {
  const socket = createSocketConnection(user_id, authToken);
  return {
    socket,
    channel: socket.private(`channel_${user_id}`), // if undefined will give Failed to Authorize websocket
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useSocketEvent(event: string, callback: Function) {
  const [user_id, token] = useAuthStore((s) => [s.userProfile?.id, s.credentials?.token]);

  useEffect(() => {
    if (window.currentWSUser && window.currentWSUser !== user_id) {
      window.Echo?.disconnect();
      window.Echo = undefined;
      window.currentWSUser = undefined;
    }
    if (!user_id || !token) {
      console.log('unable to create socket connection, user or credentials are missing');
      return;
    }
    const { channel } = createSocketWithPrivateDefaultChannel(user_id, token);
    channel.listen(event, callback);

    return () => {
      channel.stopListening(event, callback);
    };
  }, [user_id, token, event]);
}
