import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'shared/utils';

const buttonVariants = cva(
  'inline-flex gap-[10px] text-white items-center justify-center whitespace-nowrap rounded-md text-sm font-normal transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 leading-[100%]',
  {
    variants: {
      variant: {
        accept: 'bg-green-400 text-white shadow-sm hover:bg-green-400/90',
        primary: 'bg-blue font-medium text-[14px] text-primary-foreground shadow hover:bg-navy',
        subscribe: 'bg-midnight text-white  text-[14px] font-medium shadow hover:bg-bg-midnight/80',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-[#000] text-midnight bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        icon: 'py-3 px-2 border border-white rounded-md text-[#fff] shadow-sm hover:text-[#fff]',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        data: 'bg-midnight text-primary-foreground shadow hover:bg-navy font-roboto',
        cover:
          'w-full h-full _absolute_children text-[20px] tracking-[3px]  z-20 opacity-100 bg-midnight/70 rounded-0',
        navbar:
          'flex w-full bg-[#D9D9D9] justify-start cursor-pointer transition-all hover:bg-slate-100 text-midnight items-center gap-3 py-[10px] px-[6px] h-11 bg-bgGrey',
      },
      size: {
        default: 'h-9 px-4 py-2',
        subscribe: 'h-9 px-4 py-2',
        sm: 'h-8 px-3 text-xs',
        lg: 'h-10 px-8',
        icon: 'h-9 flex items-center',
        input: 'h-[29px] text-[14px] px-9 py-1 font-normal',
      },
      rounded: {
        sm: 'rounded-[10px]',
        md: 'rounded-[18px]',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, rounded, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, rounded, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
