import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

import { cn } from 'shared/utils';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '..';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn('grid gap-2', className)} {...props} ref={ref} />;
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, title, ...props }, ref) => {
  return (
    <>
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>
            <RadioGroupPrimitive.Item
              ref={ref}
              className={cn(
                `aspect-square *:font-roboto whitespace-nowrap h-8 overflow-hidden data-[state=checked]:text-white data-[state=checked]:bg-blue w-auto rounded-[8px]  text-[14px] min-w-[108px] bg-white border-primary text-primary shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50`,
                className
              )}
              {...props}
            >
              <TooltipContent className="bg-midnight border border-white" side="bottom">
                {title}
              </TooltipContent>
              <div className="truncate px-1">{title}</div>
            </RadioGroupPrimitive.Item>
          </TooltipTrigger>
        </Tooltip>
      </TooltipProvider>
    </>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
