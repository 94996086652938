import axios, { AxiosError } from 'axios';

import useAuthStore from 'shared/store/auth.slice.ts';
import { ErrorResponse, GeneralResponseListModel, GeneralResponseModel } from 'shared/types';
import { generateErrorToast } from 'shared/utils';

const instance = axios.create({
  baseURL: import.meta.env.VITE_DOMAIN_URL,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'public, max-age=3600',
  },
});

instance.interceptors.request.use(async (config) => {
  const credentials = JSON.parse(localStorage.getItem('auth') ?? '');
  if (
    credentials?.state?.credentials?.refresh_token ||
    (credentials?.state?.userProfile && !credentials?.state?.credentials)
  ) {
    delete credentials.state.credentials;
    delete credentials.state.userProfile;
    localStorage.setItem('auth', JSON.stringify(credentials));
    window.location.reload();
  }
  const token = credentials?.state?.credentials?.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['App-Key'] = import.meta.env.VITE_APP_KEY;
  return config;
});

// handle error response api
instance.interceptors.response.use(
  (response: GeneralResponseModel<unknown> | GeneralResponseListModel<unknown>) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status !== 415) {
      const headers = error.response.config.headers;
      if (!(headers && headers['No-Toast'])) {
        console.error('Error response:', error.response.data);
        generateErrorToast(error.response?.data as ErrorResponse);
      }
    }

    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('auth');
        if (!sessionStorage.getItem('url')) {
          sessionStorage.setItem('url', window.location.pathname + window.location.search);
        }
        if (window.location.href.includes('/login') || window.location.href.includes('/signup'))
          return;
        else window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

const API_VI = '/api/1';

export { instance, API_VI };
