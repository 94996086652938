import { FC, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Toaster } from 'shared/ui';
import { PageLoader } from 'entities/components';
import { useFetchProfile } from 'widgets/profile';

const Layout: FC = () => {
  const { isFetching } = useFetchProfile();

  return (
    <Suspense fallback={<PageLoader />}>
      <div className="flex min-h-svh absolute w-full top-0 left-0 max-w-[100vw] overflow-hidden">
        {isFetching ? <PageLoader /> : <Outlet />}
        <Toaster richColors position="bottom-center" />
      </div>
    </Suspense>
  );
};

export default memo(Layout);
