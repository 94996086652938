import {
  IChannelUserInfo,
  IGetUserAutocompleteParams,
  IlVerifyRequestParams,
  IUpdateAccountRequestParams,
  IUser,
  IUserAutocompleteResponse,
} from 'shared/types';
import { generateSuccessToast } from 'shared/utils';

import { API_VI, instance } from '.';

const updateAccountService = async ({
  id,
  updatedInfo,
  isShowToast,
  onSuccess,
}: IUpdateAccountRequestParams): Promise<IUser> => {
  return await instance.patch(API_VI + `/users/${id}`, { ...updatedInfo }).then((response) => {
    if (response.data?.message) {
      isShowToast && generateSuccessToast(response.data?.message);
      onSuccess?.();
    }
    return response.data.data;
  });
};

const deleteAccountService = async (id: number): Promise<IUser> => {
  return await instance.delete(API_VI + `/users/${id}?force_delete=1`).then((response) => {
    if (response.data?.message) {
      generateSuccessToast(response.data?.message);
    }
    return response.data.data;
  });
};

const verifyEmailService = async ({ id, type }: IlVerifyRequestParams): Promise<IUser> => {
  return await instance
    .get(API_VI + `/users/${id}/send-verification-email?type=${type}`)
    .then((response) => {
      if (response.data?.message) {
        generateSuccessToast(response.data?.message);
      }
      return response.data.data;
    });
};

const verifyEmailIfNeededService = async ({ id, type }: IlVerifyRequestParams): Promise<IUser> => {
  return await instance
    .get(API_VI + `/users/${id}/send-verification-email-if-needed?type=${type}`)
    .then((response) => {
      if (response.data?.message) {
        if (response.data?.message !== 'Email already sent lately') {
          generateSuccessToast(response.data?.message);
        } else {
          //generateSuccessToast('We have sent you an email recently.');
        }
      }
      return response.data.data;
    });
};

const getUserAutocompleteService = async ({
  search,
  exclude_user_ids,
}: IGetUserAutocompleteParams): Promise<IUserAutocompleteResponse> => {
  return await instance
    .get(API_VI + `/autocomplete/users`, {
      params: {
        search,
        exclude_user_ids,
      },
      paramsSerializer: {
        indexes: true,
      },
    })
    .then((response) => {
      return response.data.data;
    });
};

const getUserProfileService = async (channel_id: number): Promise<IChannelUserInfo> => {
  return await instance.get(API_VI + `/users/${channel_id}`).then((response) => response.data.data);
};

export {
  updateAccountService,
  deleteAccountService,
  verifyEmailService,
  verifyEmailIfNeededService,
  getUserAutocompleteService,
  getUserProfileService,
};
