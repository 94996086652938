import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            '[&>*]:text-white [&_svg]:mt-[2px] flex  !items-start [&_path]:fill-white group &[data-type="error"]:bg-blue-300 toast group-[.toaster]:bg-background group-[.toaster]:text-white group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-white text-white',
          actionButton:
            'text-white group-[.toast]:bg-primary group-[.toast]:text-black group-[.toast]:bg-white group-[.toast]:p-1 group-[.toast]:h-[16px] group-[.toast]:flex group-[.toast]:items-center',
          cancelButton: 'text-white group-[.toast]:bg-muted group-[.toast]:text-white',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
