import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';

import Router from 'pages/router';

import './styles/index.css';
import './styles/fonts.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10,
    },
  },
});
const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <Router />
  </QueryClientProvider>
);

export default App;
