import * as Sentry from '@sentry/react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Credentials, IUser } from 'shared/types';

type Store = {
  userProfile?: IUser;
  credentials?: Credentials;
  isNewUserRegistered: boolean;
  isNewUser: boolean;
  hasCompletedProfile: () => boolean;
  hasVerifiedEmail: () => boolean;
  setUserRegistrationStatus: (_value?: boolean) => void;
  setUserBusinessEmailVerified: (_business_email_verified_at: string) => void;
  setNewUserStatus: VoidFunction;
  setUserProfile: (_profile: IUser) => void;
  resetProfile: VoidFunction;
  setCredentials: (_credentials: Credentials) => void;
};

const useAuthStore = create<Store>()(
  persist(
    immer((set, getState) => ({
      isNewUserRegistered: false,
      isNewUser: true,
      hasCompletedProfile: () => {
        const profile = getState().userProfile;
        const experiences = profile?.experiences ?? [];
        const hasFilledOutExperience =
          experiences.length > 0 &&
          experiences[0].company &&
          experiences[0].country &&
          experiences[0].title_job;
        return Boolean(profile?.first_name && hasFilledOutExperience);
      },
      hasVerifiedEmail: () => {
        const profile = getState().userProfile;
        return Boolean(profile?.business_email_verified_at);
      },
      setUserProfile: (profile: IUser) =>
        set((state) => {
          state.userProfile = profile;
          const { id, email, business_email, name } = profile;
          const isNetnedgeAdmin = business_email?.includes('@netnedge.com');
          const isProd = import.meta.env.VITE_ENV === 'production';
          if (!isProd || isNetnedgeAdmin) Sentry.getReplay()?.stop();
          if (profile) {
            Sentry.setUser({ id, email: email || business_email || '', username: name });
          } else Sentry.setUser(null);
        }),
      setUserBusinessEmailVerified: (business_email_verified_at) => {
        set((state) => {
          if (state.userProfile)
            state.userProfile.business_email_verified_at = business_email_verified_at;
        });
      },
      setCredentials: (credentials: Credentials) =>
        set((state) => {
          state.credentials = credentials;
        }),
      resetProfile: () =>
        set((state) => {
          state.userProfile = undefined;
          state.credentials = undefined;
        }),
      setUserRegistrationStatus: (value?: boolean) =>
        set((state) => {
          if (value !== undefined) state.isNewUserRegistered = value;
          else state.isNewUserRegistered = !state.isNewUserRegistered;
        }),
      setNewUserStatus: () =>
        set((state) => {
          state.isNewUser = false;
        }),
    })),
    {
      name: 'auth',
    }
  )
);

export default useAuthStore;
