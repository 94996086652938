import { Separator } from '@/components/ui/separator.tsx';
import { cn } from '@/lib/utils.ts';
import { type Editor as TiptapEditor } from '@tiptap/core';
import CharacterCount from '@tiptap/extension-character-count';
import { Link } from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { forwardRef, useEffect } from 'react';

import { getOutput } from '../utils.ts';
import { LinkBubbleMenu } from './bubble-menu/link-bubble-menu.tsx';
import SectionTwo from './section-2.tsx';
import SectionThree from './section-3.tsx';
import SectionFour from './section-4.tsx';

import '../styles.scss';

export interface MinimalTiptapProps extends React.HTMLAttributes<HTMLDivElement> {
  outputValue?: 'html' | 'json' | 'text';
  disabled?: boolean;
  contentClass?: string;
  readOnly?: boolean;
  value?: string;
  characterLimit?: number;
  // eslint-disable-next-line no-unused-vars
  onValueChange?: (value: string) => void;
}

const BasicTextEditor = forwardRef<HTMLDivElement, MinimalTiptapProps>(
  (
    {
      readOnly: isReadOnly,
      outputValue = 'html',
      disabled,
      contentClass,
      onValueChange,
      value,
      characterLimit,
      className,
      ...props
    },
    ref
  ) => {
    if (isReadOnly) {
      disabled = true;
    }
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [],
          },
        }),
        CharacterCount.configure({
          mode: 'textSize',
          limit: characterLimit,
        }),
        Link.configure({
          openOnClick: isReadOnly,
          autolink: true,
          defaultProtocol: 'https',
          protocols: ['http', 'https', 'mailto', 'tel', 'ftp'],
          linkOnPaste: true,
          HTMLAttributes: {
            class: 'text-sky-600 dark:text-sky-400 underline',
          },
        }),
      ],
      editorProps: {
        attributes: {
          class: 'prose mx-auto focus:outline-none max-w-none prose-stone dark:prose-invert',
        },
      },
      onUpdate: (props) => {
        onValueChange?.(getOutput(props.editor, outputValue));
      },
      content: value,
      editable: !disabled,
      onCreate: ({ editor }) => {
        if (value) {
          editor.chain().setContent(value).run();
        }
      },
    });

    useEffect(() => {
      if (editor && isReadOnly && value) {
        editor.commands.setContent(value);
      }
    }, [editor, isReadOnly, value]);

    if (isReadOnly) {
      return (
        <EditorContent editor={editor} {...props} ref={ref} className={cn('', contentClass)} />
      );
    }

    const percentage =
      editor && characterLimit
        ? Math.round((100 / characterLimit) * editor.storage.characterCount.characters())
        : 0;

    return (
      <div
        className={cn(
          'flex h-auto min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-sky-600',
          className
        )}
        {...props}
        ref={ref}
      >
        {editor && (
          <>
            <LinkBubbleMenu editor={editor} />
            <Toolbar editor={editor} />
          </>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="h-full grow" onClick={() => editor?.chain().focus().run()}>
          <EditorContent editor={editor} className={cn('p-5', contentClass)} />
        </div>
        {characterLimit && editor && (
          <div className={'flex justify-end'}>
            <div
              className={`character-count ${editor.storage.characterCount.characters() === characterLimit ? 'character-count--warning' : ''}`}
            >
              <svg height="20" width="20" viewBox="0 0 20 20">
                <circle r="10" cx="10" cy="10" fill="#e9ecef" />
                <circle
                  r="5"
                  cx="10"
                  cy="10"
                  fill="transparent"
                  stroke="currentColor"
                  strokeWidth="10"
                  strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
                  transform="rotate(-90) translate(-20)"
                />
                <circle r="6" cx="10" cy="10" fill="white" />
              </svg>
              {editor.storage.characterCount.characters()} / {characterLimit} characters
              <br />
              {editor.storage.characterCount.words()} words
            </div>
          </div>
        )}
      </div>
    );
  }
);

BasicTextEditor.displayName = 'MinimalTiptapEditor';

const Toolbar = ({ editor }: { editor: TiptapEditor }) => {
  return (
    <div className="border-b border-border p-2">
      <div className="flex w-full flex-wrap items-center">
        <SectionTwo editor={editor} />
        <Separator orientation="vertical" className="mx-2 h-7" />
        <SectionThree editor={editor} />
        <Separator orientation="vertical" className="mx-2 h-7" />
        <SectionFour editor={editor} />
      </div>
    </div>
  );
};

export { BasicTextEditor };
