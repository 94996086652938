import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'app/App';
import setupSentry from 'shared/integrations/sentry';

setupSentry();

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars,react/prop-types
function RenderFallback({ error, componentStack, resetError }) {
  return (
    <React.Fragment>
      <div>You have encountered an error</div>
      <div>{error.toString()}</div>
      <div>{componentStack}</div>
      <button
        onClick={() => {
          resetError();
        }}
      >
        Click here to reset!
      </button>
    </React.Fragment>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={RenderFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
