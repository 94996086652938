import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { cn } from 'shared/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, onChange, ...props }, ref) => (
  <div className="flex items-start flex-1 space-x-2">
    <CheckboxPrimitive.Root
      ref={ref}
      onChange={onChange}
      className={cn(
        'peer h-4 w-4 shrink-0 rounded-[2px] border border-midnight shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center [&>svg>path]:fill-midnight text-current')}
      >
        <CheckIcon className="h-auto w-4 bg-white path:fill-midnight" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    <label
      htmlFor={props?.id ?? 'id'}
      className="text-[12px] flex w-full cursor-pointer mt-[-4px] leading-5 font-normal peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
    >
      {props?.title ?? ''}
    </label>
  </div>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
