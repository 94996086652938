export const SPECIAL_ERROR_LINKEDIN = 'User email not specified';

export const AGREE_WITH_POLICY_AND_AGREEMENT =
  'I consent that Netnedge may process the personal data I provide in accordance with their Privacy Policy and User Agreement';

export const MESSAGES = {
  EMPTY_ANSWER: 'Unfortunately, bot was not able to verify this fact, please try something else.',
  EMPTY_URL_ANSWER:
    'Unfortunately, bot was not able to verify this URL, please try something else.',
};

// view worth
export const LOW_VIDEO_RANGE = 40;
export const HIGH_VIDEO_RANGE = 50;

export const LINKEDIN_AUTH_URL = `${import.meta.env.VITE_DOMAIN_URL}/auth/login/linkedin`;
