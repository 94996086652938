import { clsx, type ClassValue } from 'clsx';
import { useEffect, useRef } from 'react';
import { deviceDetect, getUA, isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getDeviceName() {
  const device = deviceDetect(getUA);

  if (isMobile) {
    return `${device?.mobileVendor ?? ''} ${device?.mobileModel ?? ''} (${device?.browserName ?? ''})`.trim();
  }
  return `${device?.osName ?? ''} ${device?.osVersion ?? ''} (${device?.browserName ?? ''})`.trim();
}

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};
