import { ReactNode } from 'react';
import { toast } from 'sonner';

import { SPECIAL_ERROR_LINKEDIN } from 'shared/constants';
import { ErrorResponse } from 'shared/types';

export const generateErrorToast = (response: ErrorResponse) => {
  let error = '';
  if (response.errors && Object.keys(response.errors)?.length > 0) {
    const firstErrorKey = Object.keys(response.errors)[0];
    const errors = response.errors[firstErrorKey] as string[] | string;
    if (typeof errors === 'string') {
      // @ts-expect-error it is in fact string in this case
      error = response.errors.message;
    } else error = errors[0];
  } else {
    error = response.message;
  }

  if (error && error !== SPECIAL_ERROR_LINKEDIN) {
    toast.error(error, {
      duration: 5000,
      position: 'bottom-center',
      style: {
        background: '#ce5050',
        //borderColor: 'white',
        marginTop: '50px',
      },
      action: {
        label: '✕',
        onClick: () => console.log('Undo'),
      },
    });
  }
};

export const generateSuccessToast = (message?: ReactNode) => {
  toast.success('Success!', {
    duration: 8000,
    position: 'bottom-center',
    style: {
      background: '#5eb26d',
      //borderColor: 'white',
      marginTop: '50px',
    },
    description: message,
    action: {
      label: '✕',
      onClick: () => console.log('Undo'),
    },
  });
};

export const getWarningToast = (message?: ReactNode) => {
  toast.warning(message ?? 'Warning!', {
    duration: 5000,
    position: 'bottom-center',
    style: {
      background: '#FFA500',
      //borderColor: 'white',
      marginTop: '50px',
    },
  });
};
