import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'shared/constants';

const AuthRoute = memo(() => {
  const authData = JSON.parse(localStorage.getItem('auth') ?? '');
  const token = authData?.state?.credentials?.token;
  return !token ? <Outlet /> : <Navigate to={ROUTES.HOME} replace />;
});

export default AuthRoute;
